/**
 * Funkcja odpowiada za ukrycie preloadera po zakończeniu ładowania strony.
 * 
 * @version 1.1.0
 */
(function ($) {

    $(window).on('load', function (e) {
        if (0 < $('.js-preloader').length) {
            $('.js-preloader').addClass('preloader--hide');
        }
    });

})(jQuery);
