(function ($) {

	/**
     * Inicjalizacja podstawowych
     */
    $(document).ready(function () {
        makeTablesResponsive();
        makeSmoothScrolling();
        changeMenuToFixed();

        new universalParallax().init({
            speed: 6.0
        });
    });

    var position = $(window).scrollTop();
    $(window).scroll(function () {
        changeMenuToFixed();
    });

    /**
     * Funkcja szukająca tabeli wewnątrz tekstów pobranych z Wordpress'owego WYSIWYG
     * i "obudowująca" tabele w "table-responsive".
     * @version 1.0.0
     */
    function makeTablesResponsive () {
        let table = $('.wysiwyg-content').find('table');

        if (0 < table.length) {
            table.wrap('<div class="table-responsive"></div>');
        }
    }

    /**
     * Funkcja zarządza zmianą paska nawigacyjnego.
     * @version 1.0.0
     */
    function changeMenuToFixed() {

        let $headerNavbar = $('.js-header-navbar'),
            headerNavbarHeight = $headerNavbar.innerHeight(),
            headerTitleOffset = $('.js-home-title').offset().top,
            scroll = $(window).scrollTop();

        // Zamykamy menu na mobile
        $('#collapseSiteMenu').collapse('hide');

        // Efekty na 0 < scroll
        if (headerTitleOffset - headerNavbarHeight < $(document).scrollTop()) {
            $headerNavbar.addClass('is-scrolling');
        } else {
            $headerNavbar.removeClass('is-scrolling');
        }

        // Efekty zależne od kierunku scroll'a
        if(position < scroll) {
            $headerNavbar.addClass('hide');
        } else {
            $headerNavbar.removeClass('hide');
        }
        position = scroll;
    }

    /**
     * Funkcja odpowiada za smooth scroll w obrębie całej strony.
     * @version 1.0.1
     */
    function makeSmoothScrolling () {
        $('a[href*="#"]')
        .not('[href="#"]')                                                                  // Remove links that don't actually link to anything
        .not('[href="#0"]')
        .click(function(event) {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')    // On-page links
            && location.hostname == this.hostname) {
                var target = $(this.hash);                                                  // Figure out element to scroll to
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {                                                        // Does a scroll target exist?
                    event.preventDefault();                                                 // Only prevent default if animation is actually gonna happen
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                }
            }
        });
    }

})(jQuery);
