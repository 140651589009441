/**
 * Plik odpowiada za zarządzanie formularzem kontaktowym.
 * @version 1.0.0
 */
(function ($) {

    let $contactForm = $('.js-contact-form');
    if (0 < $contactForm.length) {
        let $mail = $contactForm.find('.js-contact-mail'),
            $phonePrefix = $contactForm.find('.js-phone-number-prefix'),
            $phone = $contactForm.find('.js-phone-number'),
            $message = $contactForm.find('.js-contact-message'),
            $hnypot = $contactForm.find('.js-hnypot'),
            $googleToken = $contactForm.find('.js-google-token'),
            $contactSendButton = $contactForm.find('.js-contact-send-button'),
            $signsLimit = $contactForm.find('.js-signs-limit');

        $phonePrefix.mask('(+00)');
        $phone.mask('000 000 000');

        $(document).ready(function () {
            updateSignsLimit();
        });
        $mail.on('input', function () {
            updateSignsLimit();
        });
        $phonePrefix.on('input', function () {
            updateSignsLimit();
        });
        $phone.on('input', function () {
            updateSignsLimit();
        });
        $message.on('input', function () {
            updateSignsLimit();
        });

        $contactForm.on('submit', function (event) {
            event.preventDefault();

            if ($hnypot.val().length <= 0) {
                $.ajax({
                    url: 'includes/contact-form/ajax-send-message.php',
                    type: 'post',
                    data: {
                        mail: $mail.val(),
                        phonePrefix: $phonePrefix.val(),
                        phone: $phone.val(),
                        message: $message.val(),
                        hnypot: $hnypot.val(),
                        googleToken : $googleToken.val()
                    },
                    beforeSend: function () {
                        $mail.attr('disabled', 'disabled');
                        $phonePrefix.attr('disabled', 'disabled');
                        $phone.attr('disabled', 'disabled');
                        $message.attr('disabled', 'disabled');
                        $contactSendButton.attr('disabled', 'disabled');
    
                        $contactSendButton.html('Wysyłam');
                    },
                    success: function (response) {
                        response = JSON.parse(response);

                        if (response.status == 'success') {
                            $mail.val(null);
                            $phonePrefix.val(null);
                            $phone.val(null);
                            $message.val(null);
                            $contactSendButton
                                .html('Wysłano')
                                .addClass('is-sent');
                            Swal.fire({
                                type: response.status,
                                title: 'Udało się!',
                                text: response.text
                            });
                        } else {
                            $contactSendButton.html('Wyślij wiadomość');
                            $mail.attr('disabled', false);
                            $phonePrefix.attr('disabled', false);
                            $phone.attr('disabled', false);
                            $message.attr('disabled', false);
                            $contactSendButton.attr('disabled', false);
    
                            let errorsList = '';
                            response.errors.forEach(element => {
                                errorsList += '<li>' + element + '</li>'; 
                            });

                            Swal.fire({
                                type: response.status,
                                title: 'Uwaga',
                                html: response.text
                                + '<ul style="text-align: left">' + errorsList + '</ul>'
                            });
    
                            // console.error(response.errors);
                        }
                    },
                    error: function (xhr, error) {
                        $contactSendButton
                            .html('Błąd')
                            .addClass('is-error');
                        Swal.fire({
                            type: 'error',
                            title: 'Błąd Ex02',
                            text: 'Podczas wysyłki wiadomości wystąpił nieoczekiwany błąd.'
                        });
                    }
                });
            } else {
                Swal.fire({
                    type: 'error',
                    title: 'Błąd',
                    text: 'Prawdopodobna próba wysłania spam-wiadomości!'
                });
            }
        });

        /**
         * Fukcja odpowiada za front-end'owe ograniczenia ilości
         * wprowadzonych znaków w formularzu kontaktowym.
         * 
         * @version 1.0.0
         */
        function updateSignsLimit () {
            let limit_signs = 450,
                actual_signs =  $mail.val().length + $phonePrefix.val().length + $phone.val().length + $message.val().length,
                signs_left = limit_signs - actual_signs;
            $signsLimit.html(signs_left);

            if (signs_left <= 0) {
                $mail.attr('maxlength', $mail.val().length);
                $phonePrefix.attr('maxlength', $phonePrefix.val().length);
                $phone.attr('maxlength', $phone.val().length);
                $message.attr('maxlength', $message.val().length);
            } else {
                $mail.attr('maxlength', null);
                $phonePrefix.attr('maxlength', null);
                $phone.attr('maxlength', null);
                $message.attr('maxlength', null);
            }
        }
    }

})(jQuery);
