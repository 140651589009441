/**
 * Funkcja odpowiada za wyświetlenie komunikatu o ciasteczkach.
 * 
 * @version 1.0.0
 */
(function ($) {

    if (typeof Cookies.get('kwiaciarenka_w_zaulku_cookie_info') === 'undefined'){
        $('.js-cookie-info').addClass('is-visible');
        $('.js-accept-cookie').on('click', function (e) {
            e.preventDefault();
            Cookies.set('kwiaciarenka_w_zaulku_cookie_info', 'Cookies!_Om_nom_nom_nom!_*Cookie_Monster_eats_all_cookies*~AlpakaPozdrawia', {
                expires: 31,
                secure: location.protocol == 'https:' ? true : false,
            });
            $('.js-cookie-info').removeClass('is-visible');
        })
    }

})(jQuery);
